.user-activity__item:hover,
.user-activity__item:focus {
  color: #16181b;
  background: #eceeef linear-gradient(180deg, #eff0f1, #eceeef) repeat-x;
  background-color: rgb(236, 238, 239);
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.something {
  opacity: 1;
  padding: 5px;
  height: 140px;
  //width: 200px;
  transition: opacity 100ms ease, height 100ms ease 200ms;
}

.something.done {
  height: 0px;
  opacity: 0;
  overflow: hidden;
}

.content {
  //padding: 5px;
  //background-color: black;
  //color: white;
  height: 195px;
  width: 195px;
}

//
// User activity
//

.user-notifications {
  &__item {
    opacity: 1;
    display: flex;
    height: 120px;
    margin-left: $up-user-activity-item-margin-left;
    border-left: $up-user-activity-item-border-left;
    border-bottom: $up-user-activity-item-border-bottom;
    font-weight: $up-user-activity-item-font-weight;
    transition: opacity 400ms ease, height 200ms ease 200ms;

    &:last-child {
      border-bottom: 0;
    }

    @include clearfix();

    &__icon {
      text-align: center;
      border-radius: 50%;
      float: left;
      width: $up-user-activity-item-icon-width;
      height: $up-user-activity-item-icon-height;
      min-width: $up-user-activity-item-icon-min-width;
      background: $up-user-activity-item-icon-bg;
      margin-left: $up-user-activity-item-icon-margin-left;
      margin-right: $up-user-activity-item-icon-margin-right;
      box-shadow: $up-user-activity-item-icon-box-shadow;

      i {
        font-size: $up-user-activity-item-icon-font-size;
        line-height: $up-user-activity-item-icon-line-height;
        color: $up-user-activity-item-icon-color;
      }
    }

    &__content {
      float: left;

      p {
        margin: 0;
      }

      a {
        font-weight: 400;
      }

      span {
        font-size: 80%;
      }
    }

    &__action {
      float: right;
    }

    &__task-list {
      list-style: none;
      margin: 0;
      padding: 0;

      // TODO: Decide if this should make it into base Shards.
      .custom-control {
        line-height: 1.5rem;
      }
    }
  }

  &__item.done {
    height: 0px;
    opacity: 0;
    //overflow: hidden;
  }
}

.user-notifications__item:hover,
.user-notifications__item:focus {
  color: #16181b;
  background: #eceeef linear-gradient(180deg, #eff0f1, #eceeef) repeat-x;
  background-color: rgb(236, 238, 239);
}

i.notification-new {
  color: #fff;
}

div.notification-new {
  background-color: $accent-color;
}

div.notification-seen {
  background-color: #f5f6f8;
  -webkit-transition: background-color 1s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

i.notification-seen {
  color: #aeb9c4;
  -webkit-transition: color 1s ease-out;
  -moz-transition: color 1s ease-out;
  -o-transition: color 1s ease-out;
  transition: color 1s ease-out;
}

.list-group-item.active {
  color: #fff;
  background-color: #674eec;
  border-color: #674eec;
}

.payout-stats {
  font-size: 0.8125rem;
}
