// Main Stylesheet
$accent-color: #674eec;
$theme-color: #674eec;
$brand-color: #674eec;
$enable-gradients: true;

// Main SDP Styles
@import "../styles/shards-dashboards";

// Variables
@import "./variables";

// Plugin Styles
@import "./dropzone";
@import "./quill";
@import "./range-date-picker";
@import "./file-manager-list";
@import "./react-tagsinput";
@import "./custom.scss";
